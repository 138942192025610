<template>
  <error-output :errors="errors">
    <a-input
      ref="focusOnMount"
      v-model="input"
      autocomplete="off"
      :class="['textinput', textTransformClass, { readOnly }]"
      :disabled="!readOnly && disabled"
      :readOnly="readOnly"
      :size="size"
      :placeholder="placeholder"
      @keypress="onKeypress"
    />
  </error-output>
</template>

<script>
import FocusOnMountMixin from '@/components/base/focusOnMount.mixin';
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'EditFormString',
  mixins: [FocusOnMountMixin],
  components: {
    ErrorOutput,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    textTransform: {
      type: String,
      default: null,
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.textTransform === 'uppercase') value = value.toUpperCase();
        if (this.textTransform === 'lowercase') value = value.toLowerCase();
        this.$emit('input', value);
      },
    },
    textTransformClass() {
      if (['uppercase', 'lowercase'].includes(this.textTransform))
        return `transform-${this.textTransform}`;

      return '';
    },
  },
  methods: {
    onKeypress(event) {
      const charCode = event.which || event.keyCode;
      if (charCode === 13) {
        this.$emit('enter', this.value);
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.textinput {
  &.readOnly {
    background-color: #fff;
    color: inherit;
    cursor: text;
  }
  &.transform-uppercase {
    text-transform: uppercase;
  }
  &.transform-lowercase {
    text-transform: lowercase;
  }
}
</style>
